import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";

const NewsLatestArticle = () => {
	const data = useStaticQuery(graphql`
		query {
			articles: allWpArticle(sort: { fields: dateGmt, order: DESC }, limit: 1) {
				nodes {
					id
					title
					excerpt
					slug
					articleFields {
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
		}
	`);
	const article = data.articles.nodes[0];
	return (
		<Row className="justify-content-center mb-6 align-items-center ">
			<Col lg={8} xl={12}>
				<div
					style={{ boxShadow: "0px 3px 50px #00000029" }}
					className="bg-white"
				>
					<Row className="justify-content-center mb-6 align-items-center h-100 ">
						<Col xl={6}>
							<Link
								className="text-decoration-none"
								to={`/news/${article.slug}`}
							>
								<GatsbyImage
									class="w-100  latest-news-image"
									image={
										article.articleFields.featuredImage?.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={article.articleFields.featuredImage?.localFile.altText}
								/>
							</Link>
						</Col>
						<Col xl={6} className=" py-5 py-xl-0 px-4 px-md-7">
							<Link
								className="text-decoration-none"
								to={`/news/${article.slug}`}
							>
								<h3 className="   pb-4 fs-1">{article.title}</h3>
							</Link>
							<p className="text-start">{parse(article.excerpt)}</p>
							<Link className="red-link py-4" to={`/news/${article.slug}`}>
								Read More
							</Link>
						</Col>
					</Row>
				</div>
			</Col>
		</Row>
	);
};

export default NewsLatestArticle;
