import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const NewsCard = ({ title, image, uri, excerpt, imageAlt }) => {
	return (
		<Col className="mb-5 pb-xl-0" md={6} lg={4} xl={3}>
			<div className="w-100  position-relative  h-100 ">
				<Link to={`/news/${uri}`}>
					<GatsbyImage
						style={{ height: "200px" }}
						class="w-100 "
						image={image}
						alt={imageAlt}
					/>
				</Link>
				<div className=" text-centerw-100 p-4">
					<Link className="text-decoration-none" to={`/news/${uri}`}>
						<h3 className=" py-3">{title}</h3>
					</Link>
					<p className="text-start">{excerpt}</p>
					<div style={{ height: "67px" }}></div>
					<Link
						className="red-link position-absolute start-50 translate-middle-x bottom-0 py-4"
						to={`/news/${uri}`}
					>
						Read More
					</Link>
				</div>
			</div>
		</Col>
	);
};

export default NewsCard;
